.menu-strip-managementstrip {
    flex: 0 0 auto;
    width: 100%;
    height: var(--dl-size-size-small);
    display: flex;
    position: relative;
    flex-wrap: wrap;
    align-items: center;
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    justify-content: flex-end;
    background-color: var(--dl-color-gray-white);
}

.menu-strip-icon {
    fill: #D9D9D9;
    width: 40px;
    height: 40px;
    max-width: 40px;
    min-width: 40px;
    max-height: 40px;
    min-height: 40px;
    margin-left: var(--dl-space-space-unit);
    cursor: pointer;
}

.menu-strip-icon2 {
    fill: #D9D9D9;
    width: 40px;
    height: 40px;
    max-width: 40px;
    min-width: 40px;
    max-height: 40px;
    min-height: 40px;
    margin-left: var(--dl-space-space-unit);
    cursor: pointer;
}

.menu-strip-icon4 {
    fill: var(--dl-color-gray-900);
    width: 40px;
    height: 40px;
    max-width: 40px;
    min-width: 40px;
    max-height: 40px;
    min-height: 40px;
    margin-left: var(--dl-space-space-unit);
    text-decoration: none;
    cursor: pointer;
}

.menu-strip-icon6 {
    fill: #D9D9D9;
    width: 40px;
    height: 40px;
    max-width: 40px;
    min-width: 40px;
    max-height: 40px;
    min-height: 40px;
    margin-left: var(--dl-space-space-unit);
    cursor: pointer;
}















@media(max-width: 479px) {
    .menu-strip-managementstrip {
        flex-wrap: wrap;
        background-color: var(--dl-color-gray-white);
    }

    .menu-strip-icon4 {
        max-width: 40px;
        min-width: 40px;
        max-height: 40px;
        min-height: 40px;
    }
}