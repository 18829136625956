.wallet-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}

.wallet-contentframe {
  flex: 1;
  width: 100%;
  height: auto;
  display: flex;
  z-index: 20;
  align-items: center;
  padding-left: 0px;
  flex-direction: column;
  justify-content: center;
}

.wallet-contentcontainer {
  flex: 1;
  width: 100%;
  height: auto;
  display: flex;
  padding: var(--dl-space-space-halfunit);
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}

.wallet-homepanel {
  flex: 1;
  width: 100%;
  height: auto;
  display: flex;
  position: relative;
  max-width: 600px;
  align-items: center;
  padding-top: var(--dl-space-space-halfunit);
  flex-direction: column;
  justify-content: flex-start;
}

.wallet-container1 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-halfunit);
  box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.05);
  align-items: flex-start;
  border-color: rgba(217, 217, 217, 0.5);
  border-style: solid;
  border-width: 1px;
  border-radius: var(--dl-radius-radius-radius16);
  flex-direction: column;
  justify-content: flex-start;
}

.wallet-headerfinance {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}

.wallet-container2 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: flex-start;
}

.wallet-icon {
  fill: #D9D9D9;
  width: 40px;
  height: 40px;
  max-width: 40px;
  min-width: 40px;
  max-height: 40px;
  min-height: 40px;
  margin-left: var(--dl-space-space-halfunit);
}

.wallet-text {
  color: var(--dl-color-gray-900);
  font-size: 32px;
  align-self: center;
  font-style: normal;
  font-family: Noto Sans;
  font-weight: 700;
  margin-left: var(--dl-space-space-halfunit);
    margin-bottom: var(--dl-space-space-unit);
}

.wallet-container3 {
  flex: 1;
  width: 100%;
  height: auto;
  display: flex;
  align-self: center;
  align-items: flex-start;
}

.wallet-icon2 {
  fill: #D9D9D9;
  width: 24px;
  height: 24px;
  align-self: center;
  margin-right: var(--dl-space-space-halfunit);
}

.wallet-icon4 {
  fill: #D9D9D9;
  width: 24px;
  height: 24px;
  align-self: center;
  margin-right: var(--dl-space-space-halfunit);
}

.wallet-select {
  width: 100%;
  height: 30px;
  margin-top: var(--dl-space-space-unit);
  padding-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-radius16);
  padding-right: var(--dl-space-space-unit);
}