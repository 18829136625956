.login-container {
    width: 100%;
    display: flex;
    overflow: auto;
    min-height: 100vh;
    align-items: center;
    flex-direction: column;
    justify-content: flex-start;
}

.login-bodycontainer {
    flex: 1;
    width: 100%;
    height: auto;
    display: flex;
    align-items: stretch;
    justify-content: flex-start;
    background-color: var(--dl-color-custom-postoffice);
}

.login-logincontainer {
    flex: 1;
    width: auto;
    height: auto;
    display: flex;
    max-width: 600px;
    align-items: center;
    flex-direction: column;
    justify-content: flex-start;
    background-color: var(--dl-color-custom-postoffice);
}

.login-image {
    width: 100%;
    object-fit: cover;
}

.login-container1 {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: flex-start;
}

.login-container2 {
    flex: 0 0 auto;
    width: 90%;
    height: auto;
    display: flex;
    align-items: center;
    margin-bottom: var(--dl-space-space-twounits);
    justify-content: center;
}

.login-text {
    color: var(--dl-color-gray-white);
    font-style: normal;
    margin-top: var(--dl-space-space-unit);
    text-align: center;
    font-weight: 700;
}

.login-loginform {
    width: 90%;
    height: auto;
    z-index: 100;
    position: relative;
    align-self: center;
    align-items: center;
    flex-direction: column;
    justify-content: flex-start;
}

.login-logintext {
    color: var(--dl-color-gray-white);
    width: 100%;
    font-size: 36px;
    font-style: normal;
    font-family: Inter;
    font-weight: 700;
    padding-left: 0px;
    margin-bottom: var(--dl-space-space-oneandhalfunits);
}

.login-loginpasswordinput {
    width: 100%;
    height: var(--dl-size-size-small);
    border-color: var(--dl-color-gray-900);
    border-width: 2px;
    border-radius: var(--dl-radius-radius-radius8);
    margin-bottom: var(--dl-space-space-unit);
}

.login-container3 {
    width: 100%;
    margin-top: var(--dl-space-space-unit);
    align-content: center;
    margin-bottom: var(--dl-space-space-twounits);
}

.login-text1 {
    color: var(--dl-color-gray-white);
    width: 100%;
    height: auto;
    align-self: center;
    font-style: normal;
    text-align: center;
    font-weight: 600;
}

.login-loginbutton {
    color: var(--dl-color-custom-postoffice);
    width: 100%;
    height: auto;
    box-shadow: rgba(0, 0, 0, 0.2) 5px 5px 10px 0px;
    font-style: normal;
    text-align: center;
    font-weight: 800;
    border-color: var(--dl-color-gray-white);
    border-width: 2px;
    border-radius: var(--dl-radius-radius-radius16);
}

.login-text2 {
    color: var(--dl-color-gray-white);
    font-size: 18px;
    font-style: normal;
    margin-top: var(--dl-space-space-unit);
    font-weight: 700;
    margin-bottom: var(--dl-space-space-threeunits);
    cursor: pointer;
}

@media(max-width: 991px) {
    .login-logincontainer {
        flex: 1;
    }

    .login-logintext {
        width: 100%;
    }

    .login-loginpasswordinput {
        width: 100%;
    }

    .login-loginbutton {
        width: 100%;
    }
}

@media(max-width: 767px) {
    .login-bodycontainer {
        flex-direction: column;
    }

    .login-logincontainer {
        width: 100%;
        max-width: 100%;
    }

    .login-container1 {
        margin-top: var(--dl-space-space-halfunit);
    }

    .login-logintext {
        width: 100%;
        margin-top: var(--dl-space-space-unit);
    }

    .login-loginpasswordinput {
        width: 100%;
    }

    .login-loginbutton {
        width: 100%;
    }
}

@media(max-width: 479px) {
    .login-bodycontainer {
        align-items: center;
        flex-direction: column;
    }

    .login-logincontainer {
        flex: 1;
        padding-top: 0px;
    }

    .login-container1 {
        margin-top: var(--dl-space-space-halfunit);
    }

    .login-loginform {
        width: 97%;
    }

    .login-logintext {
        color: var(--dl-color-gray-white);
        width: 100%;
        font-size: 36px;
        font-style: normal;
        margin-top: var(--dl-space-space-unit);
        font-family: Inter;
        font-weight: 700;
        padding-left: var(--dl-space-space-halfunit);
        padding-right: var(--dl-space-space-halfunit);
    }

    .login-loginpasswordinput {
        width: 100%;
    }

    .login-loginbutton {
        width: 100%;
    }
}