.changepassword-appparametereditor {
    flex: 0 0 auto;
    width: 100%;
    height: 100%;
    display: flex;
    padding: 15px;
    position: relative;
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-start;
    background-color: var(--dl-color-gray-white);
}

.changepassword-form {
    flex: 1;
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: flex-start;
}

.changepassword-registrationform {
    width: 100%;
    height: auto;
    display: flex;
    margin-top: 0px;
    padding-top: 0px;
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    flex-direction: column;
    padding-bottom: 0px;
}

.changepassword-textinput {
    width: 100%;
    border-color: var(--dl-color-gray-700);
    margin-right: 0px;
    border-radius: var(--dl-radius-radius-radius8);
    margin-bottom: var(--dl-space-space-unit);
}

.changepassword-textinput1 {
    width: 100%;
    border-color: var(--dl-color-gray-700);
    margin-right: 0px;
    border-radius: var(--dl-radius-radius-radius8);
    margin-bottom: var(--dl-space-space-unit);
}

.changepassword-container {
    flex: 0 0 auto;
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.changepassword-button {
    color: #0099cc;
    padding-top: 2px;
    border-color: #0099cc;
    padding-left: 4px;
    padding-right: 4px;
    padding-bottom: 2px;
}

.changepassword-icon {
    fill: #0099cc;
    width: var(--dl-size-size-xsmall);
    height: var(--dl-size-size-xsmall);
    max-width: var(--dl-size-size-xsmall);
    min-width: var(--dl-size-size-xsmall);
    max-height: var(--dl-size-size-xsmall);
    min-height: var(--dl-size-size-xsmall);
    margin-left: var(--dl-space-space-unit);
}

.changepassword-text {
    color: #0099cc;
    width: auto;
    margin-left: var(--dl-space-space-unit);
}

.changepassword-container1 {
    flex: 0 0 auto;
    width: 100%;
    height: auto;
    display: flex;
    margin-top: var(--dl-space-space-unit);
    align-items: center;
    padding-left: 0px;
    padding-right: 0px;
    justify-content: space-between;
}

.changepassword-button1 {
    color: var(--dl-color-gray-900);
    font-style: normal;
    font-weight: 700;
    border-color: var(--dl-color-gray-900);
}

.changepassword-button2 {
    color: var(--dl-color-gray-white);
    box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.15);
    font-style: normal;
    font-weight: 700;
    border-color: var(--dl-color-success-300);
    border-radius: var(--dl-radius-radius-radius8);
    background-color: var(--dl-color-success-300);
}

.changepassword-root-class-name {
    height: auto;
}

.newuserregister-text {
    color: var(--dl-color-custom-redred);
    width: 100%;
    padding-left: var(--dl-space-space-unit);
    margin-bottom: 2px;
}

@media(max-width: 479px) {
    .changepassword-appparametereditor {
        padding: var(--dl-space-space-halfunit);
    }

    .changepassword-registrationform {
        padding-left: 0px;
        padding-right: 0px;
    }
}