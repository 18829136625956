.ledger-container {
    width: 100%;
    display: flex;
    overflow: auto;
    min-height: 100vh;
    align-items: center;
    flex-direction: column;
    justify-content: flex-start;
}

.ledger-contentframe {
    flex: 1;
    width: 100%;
    height: auto;
    display: flex;
    z-index: 20;
    align-items: center;
    padding-left: 0px;
    flex-direction: column;
    justify-content: center;
}

.ledger-contentcontainer {
    flex: 1;
    width: 100%;
    height: auto;
    display: flex;
    padding: var(--dl-space-space-halfunit);
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-start;
}

.ledger-homepanel {
    flex: 1;
    width: 100%;
    height: auto;
    display: flex;
    position: relative;
    max-width: 600px;
    align-items: center;
    flex-direction: column;
    justify-content: flex-start;
}

.ledger-managers {
    width: 100%;
    height: auto;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-start;
}

.ledger-container01 {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.ledger-icon {
    fill: var(--dl-color-custom-postoffice);
    width: 30px;
    height: 30px;
    max-width: 30px;
    min-width: 30px;
    max-height: 30px;
    min-height: 30px;
    margin-left: var(--dl-space-space-halfunit);
}

.ledger-text {
    color: var(--dl-color-custom-postoffice);
    width: 100%;
    font-size: 26px;
    font-style: normal;
    font-weight: 700;
    padding-left: var(--dl-space-space-halfunit);
    padding-right: var(--dl-space-space-halfunit);
}

.ledger-container02 {
    flex: 0 0 auto;
    width: 95%;
    height: 175px;
    display: flex;
    align-self: center;
    box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.05);
    margin-top: var(--dl-space-space-unit);
    align-items: flex-start;
    border-color: rgba(217, 217, 217, 0.5);
    border-style: dashed;
    border-width: 1px;
    border-radius: var(--dl-radius-radius-radius24);
}

.ledger-container03 {
    flex: 0 0 auto;
    width: 95%;
    height: auto;
    display: flex;
    flex-wrap: wrap;
    align-self: center;
    margin-top: var(--dl-space-space-unit);
    align-items: center;
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    justify-content: space-between;
}

.ledger-text01 {
    color: var(--dl-color-custom-postoffice);
    font-size: 22px;
    font-style: normal;
    text-align: left;
    font-weight: 700;
}

.ledger-text02 {
    color: var(--dl-color-new_category-blueish);
    font-size: 22px;
    font-style: normal;
    text-align: right;
    font-weight: 700;
}

.ledger-text03 {
    color: var(--dl-color-gray-700);
    width: 95%;
    align-self: center;
    margin-top: var(--dl-space-space-halfunit);
    text-align: center;
}

.ledger-container04 {
    flex: 0 0 auto;
    width: 90%;
    height: auto;
    display: flex;
    align-self: center;
    margin-top: var(--dl-space-space-unit);
    align-items: center;
    justify-content: space-between;
}

.ledger-select {
    background: rgba(255, 255, 255, 0.5);
    border-color: rgba(217, 217, 217, 0.5);
    border-width: 1px;
    padding-left: var(--dl-space-space-unit);
    border-radius: var(--dl-radius-radius-radius16);
    padding-right: var(--dl-space-space-unit);
}

.ledger-container05 {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.ledger-icon02 {
    fill: #D9D9D9;
    width: 30px;
    height: 30px;
    max-width: 30px;
    min-width: 30px;
    max-height: 30px;
    min-height: 30px;
}

.ledger-text04 {
    color: var(--dl-color-gray-900);
    width: 30px;
    height: auto;
    font-size: 20px;
    text-align: center;
}

.ledger-icon04 {
    fill: #D9D9D9;
    width: 30px;
    height: 30px;
    max-width: 30px;
    min-width: 30px;
    max-height: 30px;
    min-height: 30px;
}

.ledger-transactionlist {
    flex: 0 0 auto;
    width: 95%;
    height: auto;
    display: flex;
    align-self: center;
    margin-top: var(--dl-space-space-halfunit);
    align-items: center;
    flex-direction: column;
    justify-content: center;
}

.ledger-container06 {
    flex: 0 0 auto;
    width: 100%;
    height: auto;
    display: flex;
    padding: var(--dl-space-space-halfunit);
    align-items: flex-start;
    border-color: rgba(217, 217, 217, 0.5);
    border-width: 1px;
    margin-bottom: var(--dl-space-space-halfunit);
    flex-direction: column;
    justify-content: flex-start;
}

.ledger-text05 {
    color: var(--dl-color-custom-krakenlogin);
    width: 100%;
    font-size: 16px;
    font-style: normal;
    text-align: left;
    font-weight: 500;
    text-transform: uppercase;
}

.ledger-text06 {
    color: var(--dl-color-gray-700);
    width: 100%;
    font-size: 20px;
    font-style: normal;
    margin-top: 4px;
    text-align: left;
    font-weight: 500;
}

.ledger-text07 {
    color: var(--dl-color-custom-krakenlogin);
    width: 100%;
    font-size: 24px;
    font-style: normal;
    margin-top: 0px;
    text-align: right;
    font-weight: 700;
    text-transform: uppercase;
}

.ledger-text08 {
    color: var(--dl-color-gray-700);
    width: 100%;
    font-size: 12px;
    font-style: normal;
    margin-top: 0px;
    text-align: left;
    font-weight: 400;
}

.ledger-container07 {
    flex: 0 0 auto;
    width: 100%;
    height: auto;
    display: flex;
    padding: var(--dl-space-space-halfunit);
    align-items: flex-start;
    border-color: rgba(217, 217, 217, 0.5);
    border-width: 1px;
    margin-bottom: var(--dl-space-space-halfunit);
    flex-direction: column;
    justify-content: flex-start;
}

.ledger-text09 {
    color: var(--dl-color-custom-redred);
    width: 100%;
    font-size: 16px;
    font-style: normal;
    text-align: left;
    font-weight: 500;
    text-transform: uppercase;
}

.ledger-text10 {
    color: var(--dl-color-gray-700);
    width: 100%;
    font-size: 20px;
    font-style: normal;
    margin-top: 4px;
    text-align: left;
    font-weight: 500;
}

.ledger-text11 {
    color: var(--dl-color-custom-redred);
    width: 100%;
    font-size: 24px;
    font-style: normal;
    margin-top: 0px;
    text-align: right;
    font-weight: 700;
    text-transform: uppercase;
}

.ledger-text12 {
    color: var(--dl-color-gray-700);
    width: 100%;
    font-size: 12px;
    font-style: normal;
    margin-top: 0px;
    text-align: left;
    font-weight: 400;
}

.ledger-container08 {
    flex: 0 0 auto;
    width: 90%;
    height: auto;
    display: flex;
    align-self: center;
    margin-top: 0px;
    align-items: center;
    margin-bottom: var(--dl-space-space-unit);
    justify-content: flex-end;
}

.ledger-container09 {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.ledger-icon06 {
    fill: #D9D9D9;
    width: 30px;
    height: 30px;
    max-width: 30px;
    min-width: 30px;
    max-height: 30px;
    min-height: 30px;
}

.ledger-text13 {
    color: var(--dl-color-gray-900);
    width: 30px;
    height: auto;
    font-size: 20px;
    text-align: center;
}

.ledger-icon08 {
    fill: #D9D9D9;
    width: 30px;
    height: 30px;
    max-width: 30px;
    min-width: 30px;
    max-height: 30px;
    min-height: 30px;
}

@media(max-width: 991px) {
    .ledger-homepanel {
        max-width: 100%;
    }
}

@media(max-width: 767px) {
    .ledger-homepanel {
        max-width: 100%;
    }
}

@media(max-width: 479px) {
    .ledger-managers {
        width: 100%;
    }
}