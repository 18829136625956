.threadusermessage-threaduseradder {
    flex: 0 0 auto;
    width: 100%;
    display: flex;
    padding: var(--dl-space-space-halfunit);
    position: relative;
    box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.05);
    align-items: center;
    border-color: rgba(217, 217, 217, 0.5);
    border-width: 1px;
    border-radius: var(--dl-radius-radius-radius16);
    margin-bottom: var(--dl-space-space-halfunit);
    flex-direction: row;
    justify-content: flex-start;
    background-color: var(--dl-color-gray-white);
}

.threadusermessage-image {
    width: var(--dl-size-size-small);
    object-fit: cover;
    border-radius: var(--dl-radius-radius-round);
}

.threadusermessage-text {
    color: var(--dl-color-gray-500);
    width: 100%;
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    padding-left: var(--dl-space-space-unit);
}

.threadusermessage-icon {
    fill: #D9D9D9;
    width: 30px;
    height: 30px;
    max-width: 30px;
    min-width: 30px;
    max-height: 30px;
    min-height: 30px;
    margin-left: var(--dl-space-space-unit);
}