.threads-container {
    width: 100%;
    display: flex;
    overflow: auto;
    min-height: 100vh;
    align-items: center;
    flex-direction: column;
    justify-content: flex-start;
}

.threads-contentframe {
    flex: 1;
    width: 100%;
    height: auto;
    display: flex;
    z-index: 20;
    align-items: center;
    padding-left: 0px;
    flex-direction: column;
    justify-content: center;
}

.threads-contentcontainer {
    flex: 1;
    width: 100%;
    height: auto;
    display: flex;
    padding: var(--dl-space-space-halfunit);
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-start;
}

.threads-homepanel {
    flex: 1;
    width: 100%;
    height: auto;
    display: flex;
    position: relative;
    max-width: 600px;
    align-items: center;
    padding-top: var(--dl-space-space-halfunit);
    padding-left: var(--dl-space-space-halfunit);
    padding-right: var(--dl-space-space-halfunit);
    flex-direction: column;
    justify-content: flex-start;
}

.threads-pochat {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: flex-start;
}

.threads-container1 {
    flex: 0 0 auto;
    width: 100%;
    height: auto;
    display: flex;
    padding: var(--dl-space-space-halfunit);
    align-items: center;
    margin-bottom: var(--dl-space-space-halfunit);
    justify-content: flex-start;
}

.threads-textinput {
    width: 100%;
    border-color: var(--dl-color-gray-900);
    border-radius: var(--dl-radius-radius-radius16);
}

.threads-icon {
    fill: #D9D9D9;
    width: 30px;
    height: 30px;
    max-width: 30px;
    min-width: 30px;
    max-height: 30px;
    min-height: 30px;
    margin-left: var(--dl-space-space-halfunit);
}

.threads-messagelist {
    flex: 0 0 auto;
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: flex-start;
}
.threads-searchcontainer {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: flex-start;
}