.transfer-container {
    width: 100%;
    display: flex;
    overflow: auto;
    min-height: 100vh;
    align-items: center;
    flex-direction: column;
    justify-content: flex-start;
}

.transfer-contentframe {
    flex: 1;
    width: 100%;
    height: auto;
    display: flex;
    z-index: 20;
    align-items: center;
    padding-left: 0px;
    flex-direction: column;
    justify-content: center;
}

.transfer-contentcontainer {
    flex: 1;
    width: 100%;
    height: auto;
    display: flex;
    padding: var(--dl-space-space-halfunit);
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-start;
}

.transfer-homepanel {
    flex: 1;
    width: 100%;
    height: auto;
    display: flex;
    position: relative;
    max-width: 600px;
    align-items: center;
    padding-top: var(--dl-space-space-halfunit);
    flex-direction: column;
    justify-content: flex-start;
}

.transfer-transfer-form {
    width: 80%;
    height: auto;
    display: flex;
    padding: var(--dl-space-space-oneandhalfunits);
    box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.05);
    align-items: center;
    border-color: rgba(23, 86, 140, 0.5);
    border-width: 1px;
    border-radius: var(--dl-radius-radius-radius16);
    margin-bottom: var(--dl-space-space-oneandhalfunits);
    flex-direction: column;
    justify-content: flex-start;
    background-color: rgba(23, 86, 140, 0.25);
}

.transfer-text {
    color: var(--dl-color-gray-white);
    width: 100%;
    font-size: 20px;
    font-style: normal;
    text-align: center;
    font-weight: 700;
    margin-bottom: var(--dl-space-space-oneandhalfunits);
}

.transfer-container1 {
    width: 95%;
    display: flex;
    align-items: flex-start;
    margin-bottom: var(--dl-space-space-unit);
    flex-direction: row;
    justify-content: center;
}

.transfer-textinput {
    width: 100%;
    align-self: center;
    border-color: rgba(23, 86, 140, 0.5);
    border-radius: var(--dl-radius-radius-radius16);
}

.transfer-icon {
    fill: var(--dl-color-gray-white);
    width: 32px;
    height: 32px;
    max-width: 32px;
    min-width: 32px;
    align-self: center;
    max-height: 32px;
    min-height: 32px;
    margin-left: var(--dl-space-space-unit);
}

.transfer-usercontainer {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: flex-start;
}

.transfer-selecteduser {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: flex-start;
}

.transfer-textinput1 {
    width: 95%;
    margin-top: var(--dl-space-space-halfunit);
    border-color: rgba(23, 86, 140, 0.5);
    margin-bottom: var(--dl-space-space-oneandhalfunits);
}

.transfer-container2 {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: center;
}

.transfer-text1 {
    margin-left: var(--dl-space-space-halfunit);
}

.transfer-button {
    color: var(--dl-color-custom-helloblue);
    width: 95%;
    box-shadow: rgba(0, 0, 0, 0.1) 5px 5px 10px 0px;
    font-style: normal;
    margin-top: var(--dl-space-space-oneandhalfunits);
    font-weight: 700;
    border-color: var(--dl-color-custom-helloblue);
    border-radius: var(--dl-radius-radius-radius16);
    margin-bottom: var(--dl-space-space-oneandhalfunits);
}









.transfermanager-container {
    width: 100%;
    display: flex;
    overflow: auto;
    min-height: 100vh;
    align-items: center;
    flex-direction: column;
    justify-content: flex-start;
}

.transfermanager-contentframe {
    flex: 1;
    width: 100%;
    height: auto;
    display: flex;
    z-index: 20;
    align-items: center;
    padding-left: 0px;
    flex-direction: column;
    justify-content: center;
}

.transfermanager-contentcontainer {
    flex: 1;
    width: 100%;
    height: auto;
    display: flex;
    padding: var(--dl-space-space-halfunit);
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-start;
}

.transfermanager-homepanel {
    flex: 1;
    width: 100%;
    height: auto;
    display: flex;
    position: relative;
    max-width: 600px;
    align-items: center;
    padding-top: var(--dl-space-space-halfunit);
    flex-direction: column;
    justify-content: flex-start;
}

.transfermanager-kyccontainer {
    flex: 0 0 auto;
    width: 100%;
    height: 100px;
    display: flex;
    padding: var(--dl-space-space-unit);
    max-width: 600px;
    box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.05);
    align-items: flex-start;
    border-color: rgba(217, 217, 217, 0.5);
    border-width: 1px;
    border-radius: var(--dl-radius-radius-radius32);
    margin-bottom: var(--dl-space-space-unit);
}

.transfermanager-container1 {
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: var(--dl-space-space-oneandhalfunits);
    flex-direction: column;
    justify-content: flex-start;
}

.transfermanager-text {
    color: var(--dl-color-custom-postoffice);
    width: 100%;
    font-size: 20px;
    font-style: normal;
    margin-top: var(--dl-space-space-oneandhalfunits);
    text-align: center;
    font-weight: 700;
    margin-bottom: var(--dl-space-space-oneandhalfunits);
}

.transfermanager-image {
    width: 100%;
    object-fit: cover;
}

.transfermanager-transfer-form {
    width: 80%;
    height: auto;
    display: flex;
    padding: var(--dl-space-space-oneandhalfunits);
    box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.05);
    align-items: center;
    border-color: rgba(23, 86, 140, 0.5);
    border-width: 1px;
    border-radius: var(--dl-radius-radius-radius16);
    margin-bottom: var(--dl-space-space-oneandhalfunits);
    flex-direction: column;
    justify-content: flex-start;
    background-color: rgba(23, 86, 140, 0.25);
}

.transfermanager-text01 {
    color: var(--dl-color-gray-white);
    width: 100%;
    font-size: 20px;
    font-style: normal;
    text-align: center;
    font-weight: 700;
    margin-bottom: var(--dl-space-space-oneandhalfunits);
}

.transfermanager-selecteduser {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: flex-start;
}

.transfermanager-useritem-selected {
    flex: 0 0 auto;
    width: 95%;
    height: auto;
    display: flex;
    position: relative;
    box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.15);
    align-items: center;
    padding-top: var(--dl-space-space-halfunit);
    border-color: rgba(217, 217, 217, 0.5);
    border-style: solid;
    border-width: 1px;
    padding-left: var(--dl-space-space-halfunit);
    border-radius: var(--dl-radius-radius-radius16);
    margin-bottom: var(--dl-space-space-halfunit);
    padding-right: var(--dl-space-space-halfunit);
    padding-bottom: var(--dl-space-space-halfunit);
    justify-content: flex-start;
    background-color: var(--dl-color-gray-white);
}

.transfermanager-image1 {
    width: auto;
    height: 60px;
    max-height: 60px;
    min-height: 60px;
    object-fit: cover;
    border-radius: var(--dl-radius-radius-round);
}

.transfermanager-container2 {
    flex: 1;
    width: 100%;
    height: auto;
    display: flex;
    padding: var(--dl-space-space-halfunit);
    align-items: flex-start;
    flex-direction: column;
}

.transfermanager-text02 {
    width: 100%;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    padding-left: 8px;
}

.transfermanager-text03 {
    color: #f05f6e;
    width: 100%;
    font-size: 24px;
    font-style: normal;
    margin-top: var(--dl-space-space-fourunits);
    text-align: center;
    font-weight: 700;
    margin-bottom: var(--dl-space-space-threeunits);
}

.transfermanager-container3 {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: center;
}

.transfermanager-text04 {
    margin-left: var(--dl-space-space-halfunit);
}

.transfermanager-button {
    color: var(--dl-color-custom-helloblue);
    width: 95%;
    box-shadow: rgba(0, 0, 0, 0.1) 5px 5px 10px 0px;
    font-style: normal;
    margin-top: var(--dl-space-space-oneandhalfunits);
    font-weight: 700;
    border-color: var(--dl-color-custom-helloblue);
    border-radius: var(--dl-radius-radius-radius16);
    margin-bottom: var(--dl-space-space-oneandhalfunits);
}

.transfermanager-success {
    width: 80%;
    height: auto;
    display: flex;
    padding: var(--dl-space-space-oneandhalfunits);
    box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.05);
    align-items: center;
    border-color: var(--dl-color-custom-krakenlogin);
    border-width: 2px;
    border-radius: var(--dl-radius-radius-radius16);
    margin-bottom: var(--dl-space-space-oneandhalfunits);
    flex-direction: column;
    justify-content: flex-start;
    background-color: var(--dl-color-gray-white);
}

.transfermanager-text05 {
    color: var(--dl-color-custom-krakenlogin);
    width: 100%;
    font-size: 24px;
    font-style: normal;
    text-align: center;
    font-weight: 700;
    margin-bottom: var(--dl-space-space-oneandhalfunits);
}

.transfermanager-icon {
    fill: var(--dl-color-custom-krakenlogin);
    width: 150px;
    height: 150px;
    max-width: 150px;
    min-width: 150px;
    max-height: 150px;
    min-height: 150px;
    margin-bottom: var(--dl-space-space-threeunits);
}

.transfermanager-button1 {
    color: var(--dl-color-custom-krakenlogin);
    width: 95%;
    box-shadow: rgba(0, 0, 0, 0.1) 5px 5px 10px 0px;
    font-style: normal;
    font-weight: 700;
    border-color: var(--dl-color-custom-krakenlogin);
    border-radius: var(--dl-radius-radius-radius16);
    margin-bottom: var(--dl-space-space-oneandhalfunits);
}

.transfermanager-error {
    width: 80%;
    height: auto;
    display: flex;
    padding: var(--dl-space-space-oneandhalfunits);
    box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.05);
    align-items: center;
    border-color: var(--dl-color-custom-redred);
    border-width: 2px;
    border-radius: var(--dl-radius-radius-radius16);
    margin-bottom: var(--dl-space-space-oneandhalfunits);
    flex-direction: column;
    justify-content: flex-start;
    background-color: var(--dl-color-gray-white);
}

.transfermanager-text06 {
    color: var(--dl-color-custom-redred);
    width: 100%;
    font-size: 24px;
    font-style: normal;
    text-align: center;
    font-weight: 700;
    margin-bottom: var(--dl-space-space-oneandhalfunits);
}

.transfermanager-icon2 {
    fill: var(--dl-color-custom-redred);
    width: 150px;
    height: 150px;
    max-width: 150px;
    min-width: 150px;
    max-height: 150px;
    min-height: 150px;
    margin-bottom: var(--dl-space-space-threeunits);
}

.transfermanager-button2 {
    color: var(--dl-color-custom-redred);
    width: 95%;
    box-shadow: rgba(0, 0, 0, 0.1) 5px 5px 10px 0px;
    font-style: normal;
    font-weight: 700;
    border-color: var(--dl-color-custom-redred);
    border-radius: var(--dl-radius-radius-radius16);
    margin-bottom: var(--dl-space-space-oneandhalfunits);
}

@media(max-width: 479px) {
    .transfermanager-transfer-form {
        width: 100%;
    }

    .transfermanager-success {
        width: 100%;
    }

    .transfermanager-error {
        width: 100%;
    }
}





@media(max-width: 479px) {
    .transfer-transfer-form {
        width: 100%;
    }
}

.kycedititem-kycedititem {
    width: 100%;
    height: auto;
    display: flex;
    position: relative;
    align-items: center;
    flex-direction: column;
    justify-content: flex-start;
    background-color: var(--dl-color-gray-white);
}

.kycedititem-multibut {
    width: 100%;
    flex-direction: row;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.kycedititem-k-cheader {
    width: 100%;
    height: auto;
    display: flex;
    padding: var(--dl-space-space-unit);
    position: relative;
    box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.05);
    align-items: stretch;
    border-color: var(--dl-color-gray-900);
    border-width: 1px;
    border-radius: 0px;
    justify-content: space-between;
}

.kycedititem-image {
    width: 140px;
    height: 140px;
    object-fit: contain;
    border-radius: 0px;
}

.kycedititem-container {
    width: 100%;
    height: auto;
    display: flex;
    padding: var(--dl-space-space-halfunit);
    align-self: flex-start;
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-start;
}

.kycedititem-container01 {
    flex: 1;
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: flex-start;
}

.kycedititem-container02 {
    width: 100%;
    height: auto;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: flex-end;
}

.kycedititem-waiting {
    color: #330af5;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
}

.kycedititem-processing {
    color: var(--dl-color-custom-newsalmon);
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
}

.kycedititem-registered {
    color: var(--dl-color-custom-krakenlogin);
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
}

.kycedititem-invalid {
    color: var(--dl-color-custom-redred);
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
}

.kycedititem-text {
    color: var(--dl-color-custom-redred);
    width: 100%;
    font-size: 20px;
    margin-top: 2px;
    text-align: right;
}

.kycedititem-container03 {
    flex: 1;
    width: 100%;
    height: auto;
    display: flex;
    align-self: flex-end;
    align-items: center;
    flex-direction: row;
    justify-content: flex-end;
    margin-bottom: 8px;
    margin-top: 4px;

}

.kycedititem-imagegrey {
    fill: #D9D9D9;
    width: 30px;
    height: 30px;
}

.kycedititem-imagegreen {
    fill: var(--dl-color-custom-krakenlogin);
    width: 30px;
    height: 30px;
}

.kycedititem-imageorange {
    fill: var(--dl-color-custom-newsalmon);
    width: 30px;
    height: 30px;
}

.kycedititem-imagered {
    fill: var(--dl-color-custom-redred);
    width: 30px;
    height: 30px;
}

.kycedititem-detgrey {
    fill: #D9D9D9;
    width: 30px;
    height: 30px;
}

.kycedititem-detgreen {
    fill: var(--dl-color-custom-krakenlogin);
    width: 30px;
    height: 30px;
}

.kycedititem-detorange {
    fill: var(--dl-color-custom-newsalmon);
    width: 30px;
    height: 30px;
}

.kycedititem-detred {
    fill: var(--dl-color-custom-redred);
    width: 30px;
    height: 30px;
}

.kycedititem-contgrey {
    fill: #D9D9D9;
    width: 30px;
    height: 30px;
    margin-left: 3px;
}

.kycedititem-contgreen {
    fill: var(--dl-color-custom-krakenlogin);
    width: 30px;
    height: 30px;
    margin-left: 3px;
}

.kycedititem-contorange {
    fill: var(--dl-color-custom-newsalmon);
    width: 30px;
    height: 30px;
    margin-left: 3px;
}

.kycedititem-contred {
    fill: var(--dl-color-custom-redred);
    width: 30px;
    height: 30px;
    margin-left: 3px;
}

.kycedititem-enhgrey {
    fill: #D9D9D9;
    width: 30px;
    height: 30px;
    margin-left: 3px;
}

.kycedititem-enhgreen {
    fill: var(--dl-color-custom-krakenlogin);
    width: 30px;
    height: 30px;
    margin-left: 3px;
}

.kycedititem-enhorange {
    fill: var(--dl-color-custom-newsalmon);
    width: 30px;
    height: 30px;
    margin-left: 3px;
}

.kycedititem-enhred {
    fill: var(--dl-color-custom-redred);
    width: 30px;
    height: 30px;
    margin-left: 3px;
}

.kycedititem-button {
    color: var(--dl-color-gray-white);
    width: var(--dl-size-size-medium);
    display: flex;
    padding: var(--dl-space-space-halfunit);
    align-self: flex-end;
    border-color: var(--dl-color-custom-newsalmon);
    padding-right: 8px;
    flex-direction: row;
    justify-content: space-between;
    background-color: var(--dl-color-custom-newsalmon);
}

.kycedititem-buttonpwd {
    color: var(--dl-color-gray-white);
    width: var(--dl-size-size-medium);
    display: flex;
    padding: var(--dl-space-space-halfunit);
    align-self: flex-end;
    border-color: var(--dl-color-custom-postoffice);
    padding-right: 8px;
    flex-direction: row;
    justify-content: space-between;
    background-color: var(--dl-color-custom-postoffice);
}

.kycedititem-text01 {
    align-self: center;
    font-style: normal;
    font-weight: 700;
}

.kycedititem-icon {
    fill: var(--dl-color-gray-white);
    width: 24px;
    height: 24px;
    align-self: center;
    padding-left: var(--dl-space-space-halfunit);
}

.kycedititem-button01 {
    color: var(--dl-color-gray-white);
    width: var(--dl-size-size-medium);
    display: flex;
    padding: var(--dl-space-space-halfunit);
    align-self: flex-end;
    align-items: center;
    border-color: var(--dl-color-custom-krakenlogin);
    flex-direction: row;
    justify-content: space-between;
    background-color: var(--dl-color-custom-krakenlogin);
}

.kycedititem-text02 {
    align-self: center;
    font-style: normal;
    font-weight: 700;
}

.kycedititem-icon02 {
    fill: var(--dl-color-gray-white);
    width: 24px;
    height: 24px;
}

.kycedititem-passwordchange {
    flex: 0 0 auto;
    width: 100%;
    height: 250px;
    display: flex;
    box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.05);
    margin-top: var(--dl-space-space-unit);
    align-items: center;
    border-color: var(--dl-color-gray-900);
    border-width: 1px;
    flex-direction: column;
    justify-content: space-between;
}

.kycedititem-text03 {
    color: var(--dl-color-custom-postoffice);
    width: 100%;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    padding-top: var(--dl-space-space-halfunit);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
}

.kycedititem-container04 {
    flex: 0 0 auto;
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    padding-top: var(--dl-space-space-halfunit);
    padding-left: var(--dl-space-space-halfunit);
    margin-bottom: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-halfunit);
    justify-content: flex-end;
}

.kycedititem-text04 {
    width: 100%;
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
}

.kycedititem-button02 {
    color: var(--dl-color-custom-krakenlogin);
    width: var(--dl-size-size-medium);
    max-width: var(--dl-size-size-medium);
    min-width: var(--dl-size-size-medium);
    border-color: var(--dl-color-custom-krakenlogin);
}

.kycedititem-container05 {
    flex: 0 0 auto;
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    padding-top: var(--dl-space-space-halfunit);
    padding-left: var(--dl-space-space-halfunit);
    margin-bottom: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-halfunit);
    justify-content: flex-end;
}

.kycedititem-text05 {
    width: 100%;
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
}

.kycedititem-button03 {
    color: var(--dl-color-custom-redred);
    width: var(--dl-size-size-medium);
    min-width: var(--dl-size-size-medium);
    border-color: var(--dl-color-custom-redred);
}

.kycedititem-container06 {
    flex: 0 0 auto;
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    padding-top: var(--dl-space-space-halfunit);
    padding-left: var(--dl-space-space-halfunit);
    margin-bottom: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-halfunit);
    justify-content: flex-end;
}

.kycedititem-text06 {
    width: 100%;
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
}

.kycedititem-button04 {
    color: var(--dl-color-custom-happyblue);
    border-color: var(--dl-color-custom-happyblue);
}

.kycedititem-showpassword {
    flex: 0 0 auto;
    width: 100%;
    height: 250px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
}

.kycedititem-text07 {
    color: var(--dl-color-custom-postoffice);
    width: 100%;
    font-size: 20px;
    font-style: normal;
    text-align: center;
    font-weight: 500;
    padding-top: var(--dl-space-space-halfunit);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
}

.kycedititem-text08 {
    color: var(--dl-color-custom-redred);
    width: 100%;
    font-size: 32px;
    font-style: normal;
    text-align: center;
    font-weight: 700;
    padding-top: 0px;
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
}

.kycedititem-button05 {
    margin-bottom: var(--dl-space-space-unit);
}

.kycedititem-allgood {
    flex: 0 0 auto;
    width: 100%;
    height: 250px;
    display: flex;
    box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.05);
    margin-top: var(--dl-space-space-unit);
    align-items: center;
    border-color: var(--dl-color-gray-900);
    border-width: 1px;
    flex-direction: column;
    justify-content: space-between;
}

.kycedititem-text09 {
    color: var(--dl-color-custom-postoffice);
    width: 100%;
    font-size: 20px;
    font-style: normal;
    text-align: center;
    font-weight: 500;
    padding-top: var(--dl-space-space-halfunit);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
}

.kycedititem-text10 {
    color: var(--dl-color-custom-krakenlogin);
    width: 100%;
    font-size: 32px;
    font-style: normal;
    text-align: center;
    font-weight: 700;
    padding-top: 0px;
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
}

.kycedititem-button06 {
    margin-bottom: var(--dl-space-space-unit);
}

.kycedititem-detailedkycform {
    width: 100%;
    height: auto;
    display: flex;
    padding: var(--dl-space-space-unit);
    margin-top: var(--dl-space-space-unit);
    align-items: center;
    flex-direction: column;
    justify-content: center;
}

.kycedititem-page01 {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}

.kycedititem-textinput {
    width: 100%;
    border-radius: var(--dl-radius-radius-radius8);
    margin-bottom: var(--dl-space-space-unit);
}

.kycedititem-container07 {
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: var(--dl-space-space-unit);
    flex-direction: row;
    justify-content: center;
}

.kycedititem-text11 {
    width: 50%;
    padding-top: var(--dl-space-space-halfunit);
    padding-left: var(--dl-space-space-halfunit);
    padding-bottom: var(--dl-space-space-halfunit);
}

.kycedititem-textinput1 {
    width: 50%;
    border-radius: var(--dl-radius-radius-radius8);
}

.kycedititem-select {
    width: 100%;
    height: 36px;
    border-radius: var(--dl-radius-radius-radius8);
    margin-bottom: var(--dl-space-space-unit);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
}

.kycedititem-container08 {
    flex: 0 0 auto;
    width: 100%;
    height: auto;
    display: flex;
    padding: 0px;
    margin-top: var(--dl-space-space-twounits);
    align-items: center;
    margin-bottom: var(--dl-space-space-unit);
    justify-content: space-between;
}

.kycedititem-button07 {
    color: var(--dl-color-gray-900);
    border-color: var(--dl-color-gray-900);
}

.kycedititem-container09 {
    flex: 0 0 auto;
    width: auto;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
}

.kycedititem-icon04 {
    fill: var(--dl-color-custom-postoffice);
    width: var(--dl-size-size-xsmall);
    height: var(--dl-size-size-xsmall);
}

.kycedititem-icon06 {
    fill: var(--dl-color-gray-900);
    width: var(--dl-size-size-xsmall);
    height: var(--dl-size-size-xsmall);
    margin-left: var(--dl-space-space-halfunit);
}

.kycedititem-icon08 {
    fill: var(--dl-color-gray-900);
    width: var(--dl-size-size-xsmall);
    height: var(--dl-size-size-xsmall);
    margin-left: var(--dl-space-space-halfunit);
}

.kycedititem-icon10 {
    fill: var(--dl-color-gray-900);
    width: var(--dl-size-size-xsmall);
    height: var(--dl-size-size-xsmall);
    margin-left: var(--dl-space-space-halfunit);
}

.kycedititem-icon12 {
    fill: var(--dl-color-gray-900);
    width: var(--dl-size-size-xsmall);
    height: var(--dl-size-size-xsmall);
    margin-left: var(--dl-space-space-halfunit);
}

.kycedititem-button08 {
    color: var(--dl-color-custom-postoffice);
    border-color: var(--dl-color-custom-postoffice);
}

.kycedititem-page02 {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}

.kycedititem-textinput2 {
    width: 100%;
    border-radius: var(--dl-radius-radius-radius8);
    margin-bottom: var(--dl-space-space-unit);
}

.kycedititem-textinput3 {
    width: 100%;
    border-radius: var(--dl-radius-radius-radius8);
    margin-bottom: var(--dl-space-space-unit);
}

.kycedititem-textinput4 {
    width: 100%;
    margin-top: var(--dl-space-space-unit);
    border-radius: var(--dl-radius-radius-radius8);
    margin-bottom: var(--dl-space-space-unit);
}

.kycedititem-select1 {
    width: 100%;
    height: 36px;
    border-radius: var(--dl-radius-radius-radius8);
    margin-bottom: var(--dl-space-space-unit);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
}

.kycedititem-textarea {
    width: 100%;
    margin-bottom: var(--dl-space-space-unit);
}

.kycedititem-container10 {
    flex: 0 0 auto;
    width: 100%;
    height: auto;
    display: flex;
    padding: 0px;
    margin-top: var(--dl-space-space-twounits);
    align-items: center;
    margin-bottom: var(--dl-space-space-unit);
    justify-content: space-between;
}

.kycedititem-button09 {
    color: var(--dl-color-gray-900);
    border-color: var(--dl-color-gray-900);
}

.kycedititem-container11 {
    flex: 0 0 auto;
    width: auto;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
}

.kycedititem-icon14 {
    fill: var(--dl-color-gray-900);
    width: var(--dl-size-size-xsmall);
    height: var(--dl-size-size-xsmall);
}

.kycedititem-icon16 {
    fill: var(--dl-color-custom-postoffice);
    width: var(--dl-size-size-xsmall);
    height: var(--dl-size-size-xsmall);
    margin-left: var(--dl-space-space-halfunit);
}

.kycedititem-icon18 {
    fill: var(--dl-color-gray-900);
    width: var(--dl-size-size-xsmall);
    height: var(--dl-size-size-xsmall);
    margin-left: var(--dl-space-space-halfunit);
}

.kycedititem-icon20 {
    fill: var(--dl-color-gray-900);
    width: var(--dl-size-size-xsmall);
    height: var(--dl-size-size-xsmall);
    margin-left: var(--dl-space-space-halfunit);
}

.kycedititem-icon22 {
    fill: var(--dl-color-gray-900);
    width: var(--dl-size-size-xsmall);
    height: var(--dl-size-size-xsmall);
    margin-left: var(--dl-space-space-halfunit);
}

.kycedititem-button10 {
    color: var(--dl-color-custom-postoffice);
    border-color: var(--dl-color-custom-postoffice);
}

.kycedititem-page03 {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}

.kycedititem-select2 {
    width: 100%;
    height: 36px;
    border-radius: var(--dl-radius-radius-radius8);
    margin-bottom: var(--dl-space-space-unit);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
}

.kycedititem-container12 {
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: var(--dl-space-space-unit);
    flex-direction: row;
    justify-content: space-between;
}

.kycedititem-text12 {
    width: 50%;
    padding-top: var(--dl-space-space-halfunit);
    padding-left: var(--dl-space-space-halfunit);
    padding-bottom: var(--dl-space-space-halfunit);
}

.kycedititem-container13 {
    width: 50%;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
}

.kycedititem-container14 {
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: var(--dl-space-space-unit);
    flex-direction: row;
    justify-content: space-between;
}

.kycedititem-text13 {
    width: 50%;
    padding-top: var(--dl-space-space-halfunit);
    padding-left: var(--dl-space-space-halfunit);
    padding-bottom: var(--dl-space-space-halfunit);
}

.kycedititem-container15 {
    width: 50%;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
}

.kycedititem-container16 {
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: var(--dl-space-space-unit);
    flex-direction: row;
    justify-content: space-between;
}

.kycedititem-text14 {
    width: 50%;
    padding-top: var(--dl-space-space-halfunit);
    padding-left: var(--dl-space-space-halfunit);
    padding-bottom: var(--dl-space-space-halfunit);
}

.kycedititem-textinput5 {
    width: 50%;
}

.kycedititem-select3 {
    width: 100%;
    height: 36px;
    border-radius: var(--dl-radius-radius-radius8);
    margin-bottom: var(--dl-space-space-unit);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
}

.kycedititem-textinput6 {
    width: 100%;
    margin-top: 0px;
    border-radius: var(--dl-radius-radius-radius8);
    margin-bottom: var(--dl-space-space-unit);
}

.kycedititem-container17 {
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: var(--dl-space-space-unit);
    flex-direction: row;
    justify-content: space-between;
}

.kycedititem-text15 {
    width: 50%;
    padding-top: var(--dl-space-space-halfunit);
    padding-left: var(--dl-space-space-halfunit);
    padding-bottom: var(--dl-space-space-halfunit);
}

.kycedititem-container18 {
    width: 50%;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
}

.kycedititem-container19 {
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: var(--dl-space-space-unit);
    flex-direction: row;
    justify-content: space-between;
}

.kycedititem-text16 {
    width: 50%;
    padding-top: var(--dl-space-space-halfunit);
    padding-left: var(--dl-space-space-halfunit);
    padding-bottom: var(--dl-space-space-halfunit);
}

.kycedititem-container20 {
    width: 50%;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
}

.kycedititem-container21 {
    flex: 0 0 auto;
    width: 100%;
    height: auto;
    display: flex;
    padding: 0px;
    margin-top: var(--dl-space-space-twounits);
    align-items: center;
    margin-bottom: var(--dl-space-space-unit);
    justify-content: space-between;
}

.kycedititem-button11 {
    color: var(--dl-color-gray-900);
    border-color: var(--dl-color-gray-900);
}

.kycedititem-container22 {
    flex: 0 0 auto;
    width: auto;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
}

.kycedititem-icon24 {
    fill: var(--dl-color-gray-900);
    width: var(--dl-size-size-xsmall);
    height: var(--dl-size-size-xsmall);
}

.kycedititem-icon26 {
    fill: var(--dl-color-gray-900);
    width: var(--dl-size-size-xsmall);
    height: var(--dl-size-size-xsmall);
    margin-left: var(--dl-space-space-halfunit);
}

.kycedititem-icon28 {
    fill: var(--dl-color-custom-postoffice);
    width: var(--dl-size-size-xsmall);
    height: var(--dl-size-size-xsmall);
    margin-left: var(--dl-space-space-halfunit);
}

.kycedititem-icon30 {
    fill: var(--dl-color-gray-900);
    width: var(--dl-size-size-xsmall);
    height: var(--dl-size-size-xsmall);
    margin-left: var(--dl-space-space-halfunit);
}

.kycedititem-icon32 {
    fill: var(--dl-color-gray-900);
    width: var(--dl-size-size-xsmall);
    height: var(--dl-size-size-xsmall);
    margin-left: var(--dl-space-space-halfunit);
}

.kycedititem-button12 {
    color: var(--dl-color-custom-postoffice);
    border-color: var(--dl-color-custom-postoffice);
}

.kycedititem-page04 {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}

.kycedititem-text17 {
    color: var(--dl-color-custom-postoffice);
    width: 100%;
    font-style: normal;
    font-weight: 700;
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-unit);
}

.kycedititem-select4 {
    width: 100%;
    height: 36px;
    border-radius: var(--dl-radius-radius-radius8);
    margin-bottom: var(--dl-space-space-unit);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
}

.kycedititem-container23 {
    flex: 0 0 auto;
    width: 100%;
    height: auto;
    display: flex;
    padding: 0px;
    margin-top: var(--dl-space-space-twounits);
    align-items: center;
    margin-bottom: var(--dl-space-space-unit);
    padding-bottom: 0px;
    justify-content: space-between;
}

.kycedititem-button13 {
    color: var(--dl-color-gray-900);
    border-color: var(--dl-color-gray-900);
}

.kycedititem-container24 {
    flex: 0 0 auto;
    width: auto;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
}

.kycedititem-icon34 {
    fill: var(--dl-color-gray-900);
    width: var(--dl-size-size-xsmall);
    height: var(--dl-size-size-xsmall);
}

.kycedititem-icon36 {
    fill: var(--dl-color-gray-900);
    width: var(--dl-size-size-xsmall);
    height: var(--dl-size-size-xsmall);
    margin-left: var(--dl-space-space-halfunit);
}

.kycedititem-icon38 {
    fill: var(--dl-color-gray-900);
    width: var(--dl-size-size-xsmall);
    height: var(--dl-size-size-xsmall);
    margin-left: var(--dl-space-space-halfunit);
}

.kycedititem-icon40 {
    fill: var(--dl-color-custom-postoffice);
    width: var(--dl-size-size-xsmall);
    height: var(--dl-size-size-xsmall);
    margin-left: var(--dl-space-space-halfunit);
}

.kycedititem-icon42 {
    fill: var(--dl-color-gray-900);
    width: var(--dl-size-size-xsmall);
    height: var(--dl-size-size-xsmall);
    margin-left: var(--dl-space-space-halfunit);
}

.kycedititem-button14 {
    color: var(--dl-color-custom-postoffice);
    border-color: var(--dl-color-custom-postoffice);
}

.kycedititem-page05 {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}

.kycedititem-text18 {
    color: var(--dl-color-custom-postoffice);
    width: 100%;
    font-style: normal;
    text-align: center;
    font-weight: 700;
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-unit);
    padding-top: var(--dl-space-space-unit);
}

.kycedititem-container25 {
    flex: 0 0 auto;
    width: 100%;
    height: auto;
    display: flex;
    padding: 0px;
    margin-top: var(--dl-space-space-twounits);
    align-items: center;
    margin-bottom: var(--dl-space-space-unit);
    padding-bottom: 0px;
    justify-content: space-between;
}

.kycedititem-button15 {
    color: var(--dl-color-custom-redred);
    border-color: var(--dl-color-custom-redred);
}

.kycedititem-container26 {
    flex: 0 0 auto;
    width: auto;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
}

.kycedititem-icon44 {
    fill: var(--dl-color-gray-900);
    width: var(--dl-size-size-xsmall);
    height: var(--dl-size-size-xsmall);
}

.kycedititem-icon46 {
    fill: var(--dl-color-gray-900);
    width: var(--dl-size-size-xsmall);
    height: var(--dl-size-size-xsmall);
    margin-left: var(--dl-space-space-halfunit);
}

.kycedititem-icon48 {
    fill: var(--dl-color-gray-900);
    width: var(--dl-size-size-xsmall);
    height: var(--dl-size-size-xsmall);
    margin-left: var(--dl-space-space-halfunit);
}

.kycedititem-icon50 {
    fill: var(--dl-color-gray-900);
    width: var(--dl-size-size-xsmall);
    height: var(--dl-size-size-xsmall);
    margin-left: var(--dl-space-space-halfunit);
}

.kycedititem-icon52 {
    fill: var(--dl-color-custom-postoffice);
    width: var(--dl-size-size-xsmall);
    height: var(--dl-size-size-xsmall);
    margin-left: var(--dl-space-space-halfunit);
}

.kycedititem-button16 {
    color: var(--dl-color-custom-postoffice);
    border-color: var(--dl-color-custom-postoffice);
}

.floatinccameraicon {
    fill: var(--dl-color-gray-900);
    width: 30px;
    height: 30px;
    align-self: center;
}



@media(max-width: 479px) {

    .kycedititem-k-cheader {

        padding: var(--dl-space-space-halfunit);

    }

    .kycedititem-detailedkycform {
        padding-left: var(--dl-space-space-halfunit);
        padding-right: var(--dl-space-space-halfunit);
    }
}



.uploadid-container {
    width: 100%;
    display: flex;
    overflow: auto;
    min-height: 100vh;
    align-items: center;
    flex-direction: column;
    justify-content: flex-start;
}

.uploadid-contentframe {
    flex: 1;
    width: 100%;
    height: auto;
    display: flex;
    z-index: 20;
    align-items: center;
    padding-left: 0px;
    flex-direction: column;
    justify-content: center;
}

.uploadid-contentcontainer {
    flex: 1;
    width: 100%;
    height: auto;
    display: flex;
    padding: var(--dl-space-space-halfunit);
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-start;
}

.uploadid-homepanel {
    flex: 1;
    width: 100%;
    height: auto;
    display: flex;
    max-width: 600px;
    align-items: center;
    flex-direction: column;
    justify-content: flex-start;
}

.uploadid-leftpaneltext {
    color: var(--dl-color-custom-postoffice);
    width: 100%;
    font-size: 20px;
    font-style: normal;
    margin-top: 0px;
    text-align: center;
    font-weight: 500;
    margin-left: 0px;
    padding-left: var(--dl-space-space-unit);
    margin-bottom: var(--dl-space-space-unit);
    margin-top: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-unit);
}

.uploadid-text {
    color: var(--dl-color-gray-700);
    width: 100%;
    margin-top: var(--dl-space-space-unit);
    text-align: center;
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
}

.uploadid-container1 {
    flex: 0 0 auto;
    width: 100%;
    height: auto;
    display: flex;
    padding: var(--dl-space-space-unit);
    margin-top: var(--dl-space-space-unit);
    align-items: center;
    justify-content: space-between;
}

.uploadid-button {
    color: var(--dl-color-custom-redred);
    border-color: var(--dl-color-custom-redred);
}

.uploadid-button1 {
    color: var(--dl-color-custom-krakenlogin);
    display: flex;
    border-color: var(--dl-color-custom-krakenlogin);
    border-radius: var(--dl-radius-radius-radius16);
    flex-direction: row;
}

.uploadid-icon {
    fill: var(--dl-color-custom-postoffice);
    width: var(--dl-size-size-xsmall);
    height: var(--dl-size-size-xsmall);
    align-self: center;
    margin-right: var(--dl-space-space-halfunit);
}

.uploadid-text1 {
    align-self: center;
}


.registar-container {
    width: 100%;
    display: flex;
    overflow: auto;
    min-height: 100vh;
    align-items: center;
    flex-direction: column;
    justify-content: flex-start;
}

.registar-contentframe {
    flex: 1;
    width: 100%;
    height: auto;
    display: flex;
    z-index: 20;
    align-items: center;
    padding-left: 0px;
    flex-direction: column;
    justify-content: center;
}

.registar-contentcontainer {
    flex: 1;
    width: 100%;
    height: auto;
    display: flex;
    padding: var(--dl-space-space-halfunit);
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-start;
}

.registar-homepanel {
    flex: 1;
    width: 100%;
    height: auto;
    display: flex;
    position: relative;
    max-width: 600px;
    align-items: center;
    flex-direction: column;
    justify-content: flex-start;
}

.registar-container1 {
    flex: 0 0 auto;
    width: 100%;
    height: auto;
    display: flex;
    min-width: auto;
    box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.15);
    min-height: auto;
    align-items: center;
    border-color: var(--dl-color-custom-postoffice);
    border-style: solid;
    border-width: 2px;
    justify-content: center;
    object-fit: fill;
}

.registar-icon {
    fill: var(--dl-color-custom-postoffice);
    width: var(--dl-size-size-xlarge);
    height: var(--dl-size-size-xlarge);
}

.registar-text {
    color: var(--dl-color-custom-postoffice);
    font-size: 20px;
    font-style: normal;
    margin-top: var(--dl-space-space-twounits);
    text-align: center;
    font-weight: 700;
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
}

.registar-camerafixer {
    flex: 0 0 auto;
    width: 100%;
    height: auto;
    display: flex;
    margin-top: var(--dl-space-space-unit);
    align-items: center;
    flex-direction: column;
    justify-content: flex-start;
}

.registar-headlinecamerafixer {
    flex: 0 0 auto;
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.registar-camerafixertext {
    color: var(--dl-color-gray-700);
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    margin-left: var(--dl-space-space-unit);
}

.registar-openicon {
    fill: var(--dl-color-gray-700);
    width: 24px;
    height: 24px;
    max-width: 24px;
    min-width: 24px;
    max-height: 24px;
    min-height: 24px;
    margin-left: var(--dl-space-space-unit);
}

.registar-closeicon {
    fill: var(--dl-color-gray-700);
    width: 24px;
    height: 24px;
    max-width: 24px;
    min-width: 24px;
    max-height: 24px;
    min-height: 24px;
    margin-left: var(--dl-space-space-unit);
}

.registar-fixcameracollapsible {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: flex-start;
}

.registar-fixcamerarefreshcontainer {
    flex: 0 0 auto;
    width: 100%;
    height: auto;
    display: flex;
    flex-wrap: wrap;
    margin-top: var(--dl-space-space-unit);
    align-items: center;
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    flex-direction: row;
    justify-content: space-between;
}

.registar-fixcamerarefreshtext {
    color: var(--dl-color-gray-700);
    font-size: 18px;
}

.registar-refreshfixcamera {
    color: var(--dl-color-custom-redred);
    width: 120px;
    font-style: normal;
    text-align: center;
    font-weight: 500;
    border-color: var(--dl-color-custom-redred);
}

.registar-fixcameraswitchcontainer {
    flex: 0 0 auto;
    width: 100%;
    height: auto;
    display: flex;
    flex-wrap: wrap;
    margin-top: var(--dl-space-space-unit);
    align-items: center;
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    flex-direction: row;
    justify-content: space-between;
}

.registar-fixcameraswitchtext {
    color: var(--dl-color-gray-700);
    font-size: 18px;
}

.registar-switchfixedcamera {
    color: var(--dl-color-custom-redred);
    width: 120px;
    font-style: normal;
    text-align: center;
    font-weight: 500;
    border-color: var(--dl-color-custom-redred);
}

.registar-fixcameralegacycontainer {
    flex: 0 0 auto;
    width: 100%;
    height: auto;
    display: flex;
    flex-wrap: wrap;
    margin-top: var(--dl-space-space-unit);
    align-items: center;
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    flex-direction: row;
    justify-content: space-between;
}

.registar-fixcameralegacytext {
    color: var(--dl-color-gray-700);
    font-size: 18px;
}

.registar-legacyfixcamera {
    color: var(--dl-color-custom-redred);
    width: 120px;
    font-style: normal;
    text-align: center;
    font-weight: 500;
    border-color: var(--dl-color-custom-redred);
}

.registar-fixcameraentercontainer {
    flex: 0 0 auto;
    width: 100%;
    height: auto;
    display: flex;
    flex-wrap: wrap;
    margin-top: var(--dl-space-space-unit);
    align-items: flex-start;
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    flex-direction: column;
    justify-content: space-between;
}

.registar-fixcameraentertext {
    color: var(--dl-color-gray-700);
    font-size: 18px;
}

.registar-fixcameraenterform {
    width: 100%;
    height: auto;
    display: flex;
    margin-top: var(--dl-space-space-halfunit);
    align-items: center;
    justify-content: space-between;
}

.registar-fixcameraenterinput {
    color: var(--dl-color-gray-500);
    width: 100%;
    border-color: var(--dl-color-gray-700);
    border-radius: var(--dl-radius-radius-radius8);
}

.registar-sendfixcamera {
    fill: var(--dl-color-custom-redred);
    width: 30px;
    height: 30px;
    max-width: 30px;
    min-width: 30px;
    max-height: 30px;
    min-height: 30px;
    margin-left: var(--dl-space-space-unit);
}

.registar-fixcamerauploadcontainer {
    flex: 0 0 auto;
    width: 100%;
    height: auto;
    display: flex;
    flex-wrap: wrap;
    margin-top: var(--dl-space-space-unit);
    align-items: flex-start;
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    flex-direction: column;
    justify-content: space-between;
}

.registar-fixcamerauploadtext {
    color: var(--dl-color-gray-700);
    font-size: 18px;
}

.registar-fixcamerauploadform {
    width: 100%;
    height: auto;
    display: flex;
    margin-top: var(--dl-space-space-halfunit);
    align-items: center;
    justify-content: space-between;
}

.registar-fixcamerauploadinput {
    color: var(--dl-color-gray-500);
    width: 100%;
    border-color: var(--dl-color-gray-700);
    border-radius: var(--dl-radius-radius-radius8);
}

.registar-uploadfixcamera {
    fill: var(--dl-color-custom-redred);
    width: 30px;
    height: 30px;
    max-width: 30px;
    min-width: 30px;
    max-height: 30px;
    min-height: 30px;
    margin-left: var(--dl-space-space-unit);
}

.registar-fixcameraexplanationcontainer {
    flex: 0 0 auto;
    width: 100%;
    height: auto;
    display: flex;
    margin-top: var(--dl-space-space-unit);
    margin-bottom: var(--dl-space-space-unit);
    align-items: center;
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    justify-content: flex-start;
}

.registar-fixcameraexplanationtext {
    color: var(--dl-color-gray-700);
}

.registar-historyorregistration {
    color: var(--dl-color-gray-900);
    width: 100%;
    font-size: 24px;
    font-style: normal;
    margin-top: var(--dl-space-space-twounits);
    margin-bottom: var(--dl-space-space-twounits);
    text-align: center;
    font-weight: 700;
}

@media(max-width: 479px) {
    .registar-fixcamerarefreshcontainer {
        align-items: flex-start;
        flex-direction: column;
    }

    .registar-refreshfixcamera {
        align-self: flex-end;
        margin-top: var(--dl-space-space-halfunit);
    }

    .registar-fixcameraswitchcontainer {
        align-items: flex-start;
        flex-direction: column;
    }

    .registar-switchfixedcamera {
        align-self: flex-end;
        margin-top: var(--dl-space-space-halfunit);
    }

    .registar-fixcameralegacycontainer {
        align-items: flex-start;
        flex-direction: column;
    }

    .registar-legacyfixcamera {
        align-self: flex-end;
        margin-top: var(--dl-space-space-halfunit);
    }

    .registar-fixcamerauploadtext {
        color: var(--dl-color-gray-700);
        font-size: 18px;
    }
}


.depositmanager-container {
    width: 100%;
    display: flex;
    overflow: auto;
    min-height: 100vh;
    align-items: center;
    flex-direction: column;
    justify-content: flex-start;
}

.depositmanager-contentframe {
    flex: 1;
    width: 100%;
    height: auto;
    display: flex;
    z-index: 20;
    align-items: center;
    padding-left: 0px;
    flex-direction: column;
    justify-content: center;
}

.depositmanager-contentcontainer {
    flex: 1;
    width: 100%;
    height: auto;
    display: flex;
    padding: var(--dl-space-space-halfunit);
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-start;
}

.depositmanager-homepanel {
    flex: 1;
    width: 100%;
    height: auto;
    display: flex;
    position: relative;
    max-width: 600px;
    align-items: center;
    padding-top: var(--dl-space-space-halfunit);
    flex-direction: column;
    justify-content: flex-start;
}

.depositmanager-kyccontainer {
    flex: 0 0 auto;
    width: 100%;
    height: auto;
    display: flex;
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-unit);
    max-width: 600px;
    box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.05);
    align-items: flex-start;
    border-color: rgba(217, 217, 217, 0.5);
    border-width: 1px;
    border-radius: var(--dl-radius-radius-radius16);
    margin-bottom: var(--dl-space-space-unit);
}

.depositmanager-deposit-form {
    width: 80%;
    height: auto;
    display: flex;
    padding: var(--dl-space-space-oneandhalfunits);
    box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.05);
    align-items: center;
    border-color: rgba(0, 152, 153, 0.5);
    border-width: 1px;
    border-radius: var(--dl-radius-radius-radius16);
    margin-bottom: var(--dl-space-space-oneandhalfunits);
    flex-direction: column;
    justify-content: flex-start;
    background-color: rgba(0, 152, 153, 0.25);
}

.depositmanager-text {
    color: var(--dl-color-gray-white);
    width: 100%;
    font-size: 20px;
    font-style: normal;
    text-align: center;
    font-weight: 700;
    margin-bottom: var(--dl-space-space-oneandhalfunits);
}

.depositmanager-textinput {
    width: 95%;
    border-color: rgba(0, 152, 153, 0.5);
    margin-bottom: var(--dl-space-space-oneandhalfunits);
}

.depositmanager-button {
    color: var(--dl-color-custom-postoffice);
    width: 95%;
    box-shadow: rgba(0, 0, 0, 0.1) 5px 5px 10px 0px;
    font-style: normal;
    font-weight: 700;
    border-color: var(--dl-color-custom-postoffice);
    border-radius: var(--dl-radius-radius-radius16);
    margin-bottom: var(--dl-space-space-oneandhalfunits);
}

.depositmanager-takepicturebox {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    margin-bottom: var(--dl-space-space-oneandhalfunits);
    flex-direction: column;
    justify-content: flex-start;
}

.depositmanager-text01 {
    color: var(--dl-color-custom-postoffice);
    width: 100%;
    font-size: 20px;
    font-style: normal;
    margin-top: var(--dl-space-space-oneandhalfunits);
    text-align: center;
    font-weight: 700;
    margin-bottom: var(--dl-space-space-oneandhalfunits);
}

.depositmanager-image {
    width: 100%;
    object-fit: cover;
}

.depositmanager-confirmation {
    width: 80%;
    height: auto;
    display: flex;
    padding: var(--dl-space-space-oneandhalfunits);
    box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.05);
    align-items: center;
    border-color: rgba(0, 152, 153, 0.5);
    border-width: 1px;
    border-radius: var(--dl-radius-radius-radius16);
    margin-bottom: var(--dl-space-space-oneandhalfunits);
    flex-direction: column;
    justify-content: flex-start;
    background-color: rgba(0, 152, 153, 0.25);
}

.depositmanager-text02 {
    color: var(--dl-color-gray-white);
    width: 100%;
    font-size: 20px;
    font-style: normal;
    text-align: center;
    font-weight: 700;
    margin-bottom: var(--dl-space-space-oneandhalfunits);
}

.depositmanager-text03 {
    color: var(--dl-color-custom-helloblue);
    width: 100%;
    font-size: 24px;
    font-style: normal;
    text-align: center;
    font-weight: 700;
    margin-bottom: var(--dl-space-space-oneandhalfunits);
}

.depositmanager-button1 {
    color: var(--dl-color-custom-postoffice);
    width: 95%;
    box-shadow: rgba(0, 0, 0, 0.1) 5px 5px 10px 0px;
    font-style: normal;
    font-weight: 700;
    border-color: var(--dl-color-custom-postoffice);
    border-radius: var(--dl-radius-radius-radius16);
    margin-bottom: var(--dl-space-space-oneandhalfunits);
}

.depositmanager-success {
    width: 80%;
    height: auto;
    display: flex;
    padding: var(--dl-space-space-oneandhalfunits);
    box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.05);
    align-items: center;
    border-color: var(--dl-color-custom-krakenlogin);
    border-width: 2px;
    border-radius: var(--dl-radius-radius-radius16);
    margin-bottom: var(--dl-space-space-oneandhalfunits);
    flex-direction: column;
    justify-content: flex-start;
    background-color: var(--dl-color-gray-white);
}

.depositmanager-text04 {
    color: var(--dl-color-custom-krakenlogin);
    width: 100%;
    font-size: 24px;
    font-style: normal;
    text-align: center;
    font-weight: 700;
    margin-bottom: var(--dl-space-space-oneandhalfunits);
}

.depositmanager-icon {
    fill: var(--dl-color-custom-krakenlogin);
    width: 150px;
    height: 150px;
    max-width: 150px;
    min-width: 150px;
    max-height: 150px;
    min-height: 150px;
    margin-bottom: var(--dl-space-space-threeunits);
}

.depositmanager-button2 {
    color: var(--dl-color-custom-krakenlogin);
    width: 95%;
    box-shadow: rgba(0, 0, 0, 0.1) 5px 5px 10px 0px;
    font-style: normal;
    font-weight: 700;
    border-color: var(--dl-color-custom-krakenlogin);
    border-radius: var(--dl-radius-radius-radius16);
    margin-bottom: var(--dl-space-space-oneandhalfunits);
}

.depositmanager-error {
    width: 80%;
    height: auto;
    display: flex;
    padding: var(--dl-space-space-oneandhalfunits);
    box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.05);
    align-items: center;
    border-color: var(--dl-color-custom-redred);
    border-width: 2px;
    border-radius: var(--dl-radius-radius-radius16);
    margin-bottom: var(--dl-space-space-oneandhalfunits);
    flex-direction: column;
    justify-content: flex-start;
    background-color: var(--dl-color-gray-white);
}

.depositmanager-text05 {
    color: var(--dl-color-custom-redred);
    width: 100%;
    font-size: 24px;
    font-style: normal;
    text-align: center;
    font-weight: 700;
    margin-bottom: var(--dl-space-space-oneandhalfunits);
}

.depositmanager-icon2 {
    fill: var(--dl-color-custom-redred);
    width: 150px;
    height: 150px;
    max-width: 150px;
    min-width: 150px;
    max-height: 150px;
    min-height: 150px;
    margin-bottom: var(--dl-space-space-threeunits);
}

.depositmanager-button3 {
    color: var(--dl-color-custom-redred);
    width: 95%;
    box-shadow: rgba(0, 0, 0, 0.1) 5px 5px 10px 0px;
    font-style: normal;
    font-weight: 700;
    border-color: var(--dl-color-custom-redred);
    border-radius: var(--dl-radius-radius-radius16);
    margin-bottom: var(--dl-space-space-oneandhalfunits);
}

@media(max-width: 479px) {
    .depositmanager-deposit-form {
        width: 100%;
    }

    .depositmanager-confirmation {
        width: 100%;
    }

    .depositmanager-success {
        width: 100%;
    }

    .depositmanager-error {
        width: 100%;
    }
}