.header-header {
  flex: 0 0 auto;
  width: 100%;
  height: 56px;
  display: flex;
  z-index: 100;
  box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.15);
  align-items: center;
  flex-direction: row;
  justify-content: flex-end;
  background-color: var(--dl-color-custom-postoffice);
}

.header-navlink {
  color: var(--dl-color-gray-white);
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  margin-left: var(--dl-space-space-halfunit);
  text-decoration: none;
}

.header-container {
  flex: 1;
  width: auto;
  height: var(--dl-size-size-xsmall);
  display: flex;
  align-items: flex-start;
}

.header-container1 {
  flex: 0 0 auto;
  width: auto;
  height: 42px;
  display: flex;
  box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.15);
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-custom-postoffice);
  border-width: 1px;
  margin-right: var(--dl-space-space-unit);
  padding-left: 4px;
  border-radius: var(--dl-radius-radius-radius8);
  padding-right: 4px;
  justify-content: space-between;
  background-color: rgba(255, 255, 255, 0.85);
}

.header-container1:hover {
  border-radius: var(--dl-radius-radius-radius16);
}

.header-icon {
  fill: var(--dl-color-gray-700);
  width: 24px;
    height: 24px;
    max-width: 24px;
    min-width: 24px;
    max-height: 24px;
    min-height: 24px;
  transition: 0.3s;
}

.header-icon:hover {
  fill: var(--dl-color-bravhur-bravhur_color);
}

.header-image {
  width: auto;
  height: 36px;
  object-fit: cover;
  margin-right: 0px;
  border-radius: var(--dl-radius-radius-round);
}

.header-root-class-name {
  top: 0px;
  left: 0px;
  right: 0px;
  position: absolute;
}

.header-root-class-name1 {
  top: 0px;
  left: 0px;
  right: 0px;
  position: absolute;
}

.header-root-class-name2 {
  top: 0px;
  left: 0px;
  right: 0px;
  position: absolute;
}

.header-root-class-name3 {
  top: 0px;
  left: 0px;
  right: 0px;
  position: absolute;
}

.header-root-class-name4 {
  top: 0px;
  left: 0px;
  right: 0px;
  position: absolute;
}

.header-root-class-name5 {
  top: 0px;
  left: 0px;
  right: 0px;
  position: absolute;
}

.header-root-class-name6 {
  top: 0px;
  left: 0px;
  right: 0px;
  position: absolute;
}

.header-root-class-name7 {
  top: 0px;
  left: 0px;
  right: 0px;
  position: absolute;
}

.header-root-class-name8 {
  top: 0px;
  left: 0px;
  right: 0px;
  position: absolute;
}

.header-root-class-name9 {
  top: 0px;
  left: 0px;
  right: 0px;
  position: absolute;
}

.header-root-class-name10 {
  top: 0px;
  left: 0px;
  right: 0px;
  position: absolute;
}

.header-root-class-name11 {
  top: 0px;
  left: 0px;
  right: 0px;
  position: absolute;
}

.header-root-class-name12 {
  top: 0px;
  left: 0px;
  right: 0px;
  position: absolute;
}

.header-root-class-name13 {
  top: 0px;
  left: 0px;
  right: 0px;
  position: absolute;
}

.header-root-class-name14 {
  top: 0px;
  left: 0px;
  right: 0px;
  position: absolute;
}

.header-root-class-name15 {
  top: 0px;
  left: 0px;
  right: 0px;
  position: absolute;
}

.header-root-class-name16 {
  top: 0px;
  left: 0px;
  right: 0px;
  position: absolute;
}

.header-navlink1 {
  display: contents;
}

.header-nounread {
  fill: var(--dl-color-gray-700);
  width: 24px;
  height: 24px;
  max-width: 24px;
  min-width: 24px;
  max-height: 24px;
  min-height: 24px;
  margin-left: var(--dl-space-space-halfunit);
  margin-right: var(--dl-space-space-halfunit);
  text-decoration: none;
}

.header-navlink2 {
  display: contents;
}

.header-unread {
  fill: var(--dl-color-custom-redred);
  width: 24px;
  height: 24px;
  max-width: 24px;
  min-width: 24px;
  max-height: 24px;
  min-height: 24px;
  margin-left: var(--dl-space-space-halfunit);
  margin-right: var(--dl-space-space-halfunit);
  text-decoration: none;
}